import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Login.vue'
import Contador from '../views/Contador.vue'
import Dashboard from '../views/Dashboard.vue'
import Espacos from '../views/Espacos.vue'
import Datas from '../views/Datas.vue'
import Utilizadores from '../views/Utilizadores.vue'
import Controlos from '../views/Controlos.vue'
import Widget from '../views/Widget.vue'

import utilizadoesService from '@/services/utilizadores'

// eslint-disable-next-line no-unused-vars
const esperaLogin = function(to, from, next){

  utilizadoesService.pedirUtlilizadorPorToken()
      .then(function(resposta){

        // Compara o accesso necessário com o acesso efetivo na BD

        if(!resposta){
          next('/');
        }

        // Acesso só Admin
        if(to.meta.acesso === 'admin' && resposta.util_func === 'admin'){
          next();
          return;
        }

        // Utilizador - o admin também tem accesso
        if(to.meta.acesso === 'util' && resposta.util_func === 'admin'){
          next();
          return;
        }

        // O utilizador tem acesso apenas aos seus
        if(to.meta.acesso === 'util' && resposta.util_func === 'util'){
          next();
          return;
        }

        alert('Não tem acesso a este ecrá')

        utilizadoesService.logout()
            .then(function(){
              next('/');
            })

      })


}



const routes = [
  {
    path: '/',
    name: 'Login',
    component: Home
  },
  {
    path: '/contador',
    name: 'Contador',
    component: Contador,
    beforeEnter: esperaLogin,
    meta: {"acesso": "util"}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: esperaLogin,
    meta: {"acesso": "admin"}
  },
  {
    path: '/espacos',
    name: 'Espacos',
    component: Espacos,
    beforeEnter: esperaLogin,
    meta: {"acesso": "admin"}
  },
  {
    path: '/datas',
    name: 'Datas',
    component: Datas,
    beforeEnter: esperaLogin,
    meta: {"acesso": "admin"}
  },
  {
    path: '/utilizadores',
    name: 'Utilizadores',
    component: Utilizadores,
    beforeEnter: esperaLogin,
    meta: {"acesso": "admin"}
  },
    {
        path: '/controlos',
        name: 'Controlos',
        component: Controlos,
        beforeEnter: esperaLogin,
        meta: {"acesso": "admin"}
    },
    {
        path: '/widget',
        name: 'Widget',
        component: Widget
    }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})



export default router
