import lsService from '@/services/localstorage'

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

import axios from 'axios'

export default {
    pedirTudo: function(){
        let params = {
            "accao" : "pedirTudo",
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'datas.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    },
    gravar: function(registo){

        var formData = new FormData;

        formData.append("accao", "gravar");
        formData.append("dados", JSON.stringify(registo));
        formData.append("token", lsService.ler());

        let ajax = axios.post(apiEndpoint + 'datas.php?rnd=' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;

    },
    apagar: function(registo){

        var formData = new FormData;

        formData.append("accao", "apagar");
        formData.append("dados", JSON.stringify(registo));
        formData.append("token", lsService.ler());

        let ajax = axios.post(apiEndpoint + 'datas.php?rnd=' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;

    }
}