<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador></MenuAdministrador>

    <div class="datas">
      <div class="datasTitulo">Data</div>
      <div class="datasTitulo" style="text-align: center;">Ativo</div>
      <div class="datasTitulo"></div>
      <div class="datasTitulo">
        <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                @click="novo()"
        >adicionar</button>
      </div>
    </div>

<!--    <pre style="position: relative; z-index: 10;">{{datas}}</pre>-->

    <div class="datasDados" v-for="(data, i) in datas" :key="i">
          <div class="datasValores">
          <input type="date" v-model="data.data_data" />
        </div>
        <div class="datasValores" style="text-align: center;">
          <input type="checkbox" v-model="data.data_acti" />
        </div>
        <div class="datasValores">
          <button class="botao botaoTransparente botaoComRebordo botaoApagar" style="color: white;"
                  @click="apagar(data, i)"
              >apagar</button>
        </div>
        <div class="datasValores">
          <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                  @click="gravar(data)"
              >gravar</button>
        </div>
      </div>





  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'
  import datasService from '@/services/datas'
  import controlosService from '@/services/controlos'


  export default {
    name: 'Datas',
    components: {
      MenuAdministrador
    },
    data: function () {
      return {
        "datas": []
      }
    },
    methods: {

        todasAsDatas: function(){
            let that = this;

            datasService.pedirTudo()
                .then(function(resposta){

                    let nrDatas = resposta.length;

                    for(let i = 0; i < nrDatas; i++){
                        if(resposta[i].data_acti == 1){
                            resposta[i].data_acti = true;
                        }else{
                            resposta[i].data_acti = false;
                        }
                    }

                    that.datas = resposta;
                })

        },
        novo: function(){
            let that = this;

            let novo = {
                "data_iden": 0,
                "data_data": '',
                "data_acti": true
            }

            let stringJson = JSON.stringify(novo);
            that.datas.unshift(JSON.parse(stringJson))

        },
        gravar: function(data){

            let that = this;

            // Valida se tem nome
            if(data.data_data.trim().length != 10){
                that.$wkToast('Tem que indicar uma data válida!',
                    {
                        className: ['wk-alert'],
                    }
                );
                return;
            }


            datasService.gravar(data).
            then(function(resposta){

                if(resposta['estado'] === 'ok'){
                    that.todasAsDatas();
                    setTimeout(function(){
                        that.$wkToast('Registo gravado.');
                    }, 0)
                }

                if(resposta['estado'] === 'erro'){
                    that.$wkToast(resposta.mensagens,
                        {
                            className: ['wk-alert'],
                        }
                    );
                }

            })
        },
        apagar: function(registo, i){

            let that = this;

            if(registo.data_iden === 0){
                that.datas.splice(i, 1);
                return;
            }

            var confirmacao = confirm('Confirma que quer apagar este registo?');

            if(confirmacao){

                // Se foi confirmado
                // Volta ao servidor para saber se existem utilizador associados a esta data


                controlosService.pedirControlosParaUmaData(registo.data_iden)
                    .then(function(resposta){
                        if(resposta['controladores'].length > 0){

                            var novaConfirmacao = confirm('Esta data contém utilizadores associados nos controlos? Quer mesmo apagar?');

                            if(novaConfirmacao){
                                that.apagarMesmo(registo);
                            }


                        }else{
                            that.apagarMesmo(registo);
                        }
                    })



            }

        },
        apagarMesmo: function(registo){

            var that = this;

            datasService.apagar(registo)
                .then(function(resposta){

                    if(resposta['estado'] === 'ok'){
                        that.todasAsDatas();
                        setTimeout(function(){
                            that.$wkToast('Registo apagado.');
                        }, 0)
                    }

                    if(resposta['estado'] === 'erro'){
                        that.$wkToast(resposta.mensagens,
                            {
                                className: ['wk-alert'],
                            }
                        );
                    }

                })
        }

    },
    mounted: function(){


        this.todasAsDatas();


    },
    watch: {

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .datas{
    @include cintas($larguraDefault / 2.5);
    position: relative;
    z-index: 5;
    padding-top: 100px;
    background-color: $corPrincipal;
    color: white;
    display: grid;
    grid-template-columns: 1fr 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
  }

  .datasTitulo{
    font-weight: bold;
    padding-bottom: 20px;
  }

  .datasDados{
    @include cintas($larguraDefault / 2.5);
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $corEscura;
  }


</style>