<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador
      :esconderMenus="true"
      ></MenuAdministrador>

    <div class="dashboard">


<!--      <div class="dashboardItem">-->

<!--        <div class="dashboardIcon">-->
<!--          <div class="contagem" v-if="totais">-->
<!--            <div class="noEspaco" v-html="formatarInteiros(totais.lotacao)" style="color: white;"></div>-->
<!--            <div class="lotacao" v-html="formatarInteiros(totais.ocupacao)"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="nomeDoEspaco">-->
<!--          Ocupação geral-->
<!--        </div>-->
<!--      </div>-->

        <div class="dashboardItem" v-for="(espaco, i) in espacos" :key="i" :style="{'background-color': calcularCor(espaco.ocupacao / espaco.espa_lota) }">


          <div class="dashboardIcon">
            <div class="contagem">
              <div class="noEspaco" v-html="formatarInteiros(espaco.espa_lota)"></div>
              <div class="lotacao" v-html="formatarInteiros(espaco.ocupacao)"></div>
            </div>
          </div>
          <div class="nomeDoEspaco">
            {{espaco.espa_nome}}
          </div>
        </div>

    </div>



  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'

  import datasService from '@/services/datas'
  import dashboardServices from '@/services/dashboard'

  export default {
  name: 'Contador',
  components: {
    MenuAdministrador
  },
  data: function () {
    return {
      "todasAsDatas": [],
      "dataAtiva": {},
      "espacos": [],
      "totais": {},
      "graficos": [],
      "listagem": []
    }
  },
  methods: {

    calcularCor: function (pct) {

      var percentColors = [
        { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
        { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
        { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } }
        ];

      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break;
        }
      }
      var lower = percentColors[i - 1];
      var upper = percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };

      return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 0.9)';

    },
    calcularPercentagem: function(){
      let that = this;

      var percentagem = that.numeros.noEspaco / that.numeros.lotacao;
      that.calcularCor(percentagem)

    },
    formatarInteiros: function(inteiro){
      if(!inteiro){
        return 0;
      }
      return inteiro.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    mostrarUtilizadores: function(indice){
      document.getElementById('listaUtilizadores_' + indice).style.display = 'block';
    },
    fecharListaUtilizadores: function(indice){
      document.getElementById('listaUtilizadores_' + indice).style.display = 'none';
    },
    mostrarCalendario: function(){
      document.getElementById('calendario').style.display = 'block';
    },
    fecharCalendario: function(){
      document.getElementById('calendario').style.display = 'none';
    },
    pedirDadosParaAData: function(){
      let that = this;
      that.graficos = [];
      dashboardServices.pedirDadosDashboard(that.dataAtiva)
              .then(function(resposta){
                that.espacos = resposta.espacos;

                var nrEspacos = that.espacos.length;

                for(var i = 0; i < nrEspacos; i++){
                  if(that.espacos[i]['ocupacao'] > that.espacos[i]['espa_lota']){
                    that.espacos[i]['ocupacao'] = that.espacos[i]['espa_lota'];
                  }
                  if(that.espacos[i]['ocupacao'] < 0){
                    that.espacos[i]['ocupacao'] = 0;
                  }
                }

                that.totais = resposta.totais;

                // that.$nextTick(function(){
                //   // Gera os gráficos dos espaços
                //   // var cnvs = document.querySelectorAll('[data-grafico="espaco"]')
                //   // var nrCanvas = cnvs.length;
                //
                //   // for(var i = 0; i < nrCanvas; i++){
                //   //   that.gerarMapa(i, that.espacos[i]['grafico']);
                //   // }
                //   //
                //   // // Gera gráfico dos totais
                //   // that.gerarMapa(cnvs.length, that.totais.grafico);
                //
                // })


              })
    },
    pedirTudo: function(){
      let that = this;


      // Vê qual a data ativa
      datasService.pedirTudo()
              .then(function(todasAsDatas){
                let nrDatas = todasAsDatas.length;

                that.todasAsDatas = todasAsDatas;

                for(let i = 0; i < nrDatas; i++){
                  if(todasAsDatas[i].data_acti == 1){
                    that.dataAtiva = todasAsDatas[i];
                  }
                }

                that.pedirDadosParaAData();

              })


      that.calcularCor(0);
    }
  },
  mounted: function(){

    let that = this;

    setInterval(function(){
      that.pedirTudo();
    }, 1000)


  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .dashboard{
    @include cintas($larguraDefault);
    position: relative;
    padding-top: 80px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
  }

  canvas{
    max-width: 100%;
  }

  .dashboardItem{
    background-color: $corEscura;
    padding: 20px;
    position: relative;
    /*max-width: 477px;*/
  }

  .dashboardIcon{
    position: relative;
    max-width: 350px;
    margin: 0 auto;
  }

  .contagem{
    /*position: absolute;*/
    text-align: center;
    color: $corPrincipal;
    margin-bottom: 30px;
  }

  .noEspaco{
      font-size: 50px;
      color: $corEscura;
  }

  .lotacao{
      font-size: 24px;
  }


  .nomeDoEspaco{
    color: rgba(white, 0.8);
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }

  .utilizadores{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: $corEscura;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    z-index: 3;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    box-shadow: 2px 2px 5px 0px rgba(255,255,255,0.75);
  }

  .listaUtilizadores{
    background-color: $corEscura;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    color: white;
    overflow: auto;
    display: none;
  }

  .listaUtilizadoresFechar{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    background-color: $corEscura;
    top: 0;
    span{
      padding: 15px;
      cursor: pointer;
    }
  }

  .listaUtilizadoresItem{
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(white, 0.2);
    &:nth-last-child(1){
      margin-bottom: 50px;
    }
  }

  .listaUtilizadoresItemNome{
    padding-top: 15px;
    padding-bottom: 15px;
    flex-grow: 1;
  }

  .listaUtilizadoresItemTelefone{
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    svg{
      margin-left: 10px;
      max-height: 16px;
    }
  }

  .listaDeDatas{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
  }

  .umaData{
    border: 1px solid white;
    font-weight: bold;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.25s;
    &:hover{
      background-color: white;
      color: $corPrincipal;
      transition: all 0.25s;
    }
  }

  .dataSelecionada{
    background-color: white;
    color: $corEscura;
  }

  #listagem{
    display: none;
  }

  @media only screen and (max-width: 1100px) {
    .dashboard{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 850px) {

    .dashboard{
      grid-template-columns: repeat(2, 1fr);
    }

    .grafico{
      display: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .dashboard{
      grid-template-columns: 1fr;
    }
  }


</style>