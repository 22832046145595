<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador></MenuAdministrador>

      <div class="filtros">
          <div class="utilizadoresTitulo">Filtrar</div>
          <div class="filtrosInput">
              <input type="search" v-model="stringFiltro" />
              <button class="botao botaoTransparente botaoComRebordo" @click="stringFiltro = ''">
                  <svg class="w-6 h-6" fill="currentcolor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
              </button>
          </div>
      </div>

    <div class="utilizadores">
      <div class="utilizadoresTitulo">Nome</div>
      <div class="utilizadoresTitulo">Email</div>
      <div class="utilizadoresTitulo">Telemóvel</div>
      <div class="utilizadoresTitulo">Função</div>
      <div class="utilizadoresTitulo">Password</div>
      <div class="utilizadoresTitulo">Editar password</div>
      <div class="utilizadoresTitulo">Ativo</div>
      <div class="utilizadoresTitulo"></div>
      <div class="utilizadoresTitulo">
        <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                @click="novo()"
        >adicionar</button>
      </div>
    </div>

<!--    <pre style="position: relative; z-index: 10;">{{utilizadores}}</pre>-->

      <span v-for="(user, i) in utilizadores" :key="i">
          <div class="utilizadoresDados" v-if="filtrar(user)">
        <div class="utilizadoresValores">
            <input type="text" v-model="user.util_nome" autocomplete="off" />
        </div>
        <div class="utilizadoresValores">
            <input type="email" v-model="user.util_emai" :disabled="user.util_iden > 0" autocomplete="off" />
        </div>
        <div class="utilizadoresValores">
            <input type="text" style="text-align: center;" v-model="user.util_tele" autocomplete="off" />
        </div>
        <div class="utilizadoresValores">
            <select v-model="user.util_func">
                <option value="util">Utilizador</option>
                <option value="admin">Administrador</option>
            </select>
        </div>
        <div class="utilizadoresValores">
            <input type="text" style="text-align: center;" v-model="user.util_pass" :disabled="!user.util_mpas" autocomplete="off" />
        </div>
        <div class="utilizadoresValores" style="text-align: center">
            <input type="checkbox" v-model="user.util_mpas" />
        </div>
        <div class="utilizadoresValores" style="text-align: center">
            <input type="checkbox" v-model="user.util_acti" />
        </div>
        <div class="utilizadoresValores">
            <button class="botao botaoTransparente botaoComRebordo botaoApagar" style="color: white;"
                    @click="apagar(user)"
            >apagar</button>
        </div>
        <div class="utilizadoresValores">
            <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                    @click="gravar(user, i)"
            >gravar</button>
        </div>
    </div>
      </span>






  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'
  import utilizadoresService from '@/services/utilizadores'

  export default {
    name: 'Utilizadores',
    components: {
      MenuAdministrador
    },
    data: function () {
      return {
            "utilizadores": [],
            "emailsUsados": [],
            "stringFiltro": ''
      }
    },
    methods: {

        todosOsUtilizadores: function(){
            let that = this;

            that.utilizadores = [];
            that.emailsUsados = [];

            utilizadoresService.pedirTudo()
                .then(function(resposta){

                    let nrUtilizadores = resposta.length;

                    for(let i = 0; i < nrUtilizadores; i++){
                        if(resposta[i].util_acti == 1){
                            resposta[i].util_acti = true;
                        }else{
                            resposta[i].util_acti = false;
                        }

                        resposta[i]['util_mpas'] = false;

                        that.emailsUsados.push(resposta[i]['util_emai']);

                    }

                    that.utilizadores = resposta;
                })

        },
        novo: function(){
            let that = this;

            let novo = {
                "util_iden": 0,
                "util_acti": true,
                "util_func": "util",
                "util_mpas": true,
                "util_pass": "",
                "util_emai": ""
            }

            let stringJson = JSON.stringify(novo);
            that.utilizadores.unshift(JSON.parse(stringJson))

        },
        gravar: function(user){

            let that = this;

            // Valida se tem nome
            if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(user.util_emai)){
                that.$wkToast('Email inválido.',
                    {
                        className: ['wk-alert'],
                    }
                );
                return;
            }

            if(user.util_iden == 0){
                user.util_mpas = true;
            }

            // Novo utilizador
            if(user.util_iden == 0 && (user.util_pass).trim().length < 7){
                that.$wkToast('A password deve ter, pelo menos, 7 caracteres.',
                    {
                        className: ['wk-alert'],
                    }
                );
                return;
            }

            // Se chegou aqui sem password ou não é para trocar ou tem erro
            if(user.util_mpas === true && (user.util_pass).trim().length < 7){
                that.$wkToast('A password deve ter, pelo menos, 7 caracteres.',
                    {
                        className: ['wk-alert'],
                    }
                );
                return;
            }


            utilizadoresService.gravar(user).
            then(function(resposta){

                if(resposta['estado'] === 'ok'){
                    that.todosOsUtilizadores();
                    setTimeout(function(){
                        that.$wkToast('Registo gravado.');
                    }, 0)
                }

                if(resposta['estado'] === 'erro'){
                    that.$wkToast(resposta.mensagens,
                        {
                            className: ['wk-alert'],
                        }
                    );
                }

            })
        },
        filtrar: function(util){

            let that = this;

            let strRegExp = that.stringFiltro.replace(' ', '(.*)');
            strRegExp = '(.*)' + strRegExp + '(.*)';
            strRegExp.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            let regx = new RegExp(strRegExp, 'i');

            return regx.exec(util.util_emai) || regx.exec(util.util_nome);

        },
        apagar: function(registo, i){

            let that = this;

            if(registo.util_iden === 0){
                that.utilizadores.splice(i, 1);
                return;
            }

            var confirmacao = confirm('Confirma que quer apagar este registo?');

            if(confirmacao){
                utilizadoresService.apagar(registo)
                    .then(function(resposta){

                        if(resposta['estado'] === 'ok'){
                            that.todosOsUtilizadores();
                            setTimeout(function(){
                                that.$wkToast('Registo apagado.');
                            }, 0)
                        }

                        if(resposta['estado'] === 'erro'){
                            that.$wkToast(resposta.mensagens,
                                {
                                    className: ['wk-alert'],
                                }
                            );
                        }

                    })
            }

        }

    },
    mounted: function(){

        this.todosOsUtilizadores();

    },
    watch: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .utilizadores{
    @include cintas($larguraDefault);
    position: relative;
    z-index: 5;
    padding-top: 100px;
    background-color: $corPrincipal;
    color: white;
    display: grid;
    grid-template-columns: 1fr 250px 150px 150px 150px 100px 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
  }

  .utilizadoresTitulo{
    font-weight: bold;
    padding-bottom: 20px;
    text-align: center;
  }

  .utilizadoresDados{
    @include cintas($larguraDefault);
    position: relative;
    z-index: 5;
    display: grid;
      grid-template-columns: 1fr 250px 150px 150px 150px 100px 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $corEscura;
  }

  .filtros{
      @include cintas($larguraDefault);
      position: relative;
      padding-top: 60px;
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: $corEscura;
      color: white;
      padding-bottom: 15px;
      .utilizadoresTitulo{
          padding: 0;
          padding-right: 15px;
      }
  }

    input{
        &:disabled{
            pointer-events: none;
            user-select: none;
        }
    }

    .filtrosInput{
        display: flex;
        button{
            padding: 10px;
            background-color: white;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            svg{
                width: 24px;
            }
        }
    }

    input[type="password"]{
        &:disabled{
            color: white;
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        }
    }


</style>