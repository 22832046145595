import lsService from '@/services/localstorage'

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

import axios from 'axios'

export default {
    pedirColocacaoDoUtilizador: function(dados){
        let params = {
            "accao" : "pedirColocacaoDoUtilizador",
            "token": lsService.ler(),
            "dados": JSON.stringify(dados),
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'contador.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    },
    gravarRegisto: function(tarefa){

        var formData = new FormData;

        formData.append("accao", "gravar");
        formData.append("dados", JSON.stringify(tarefa));
        formData.append("token", lsService.ler());

        let ajax = axios.post(apiEndpoint + 'contador.php?rnd=' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(() => {
                // eslint-disable-next-line
                // console.log(e.toJSON());
            });
        return ajax;

    },

}