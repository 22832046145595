<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuUtilizador></MenuUtilizador>

    <div class="caixaContador" v-if="tarefa.espa_lota">
      <div class="caixaContadorCima">
        <div class="icon">
          <div class="contagem">
            <div class="contagemNoEspaco" v-html="formatarInteiros(tarefa.ocupacao)"></div>
            <div class="contagemNoLotacao" v-html="formatarInteiros(tarefa.espa_lota)"></div>
          </div>
          <svg class="w-6 h-6" :fill="corCasa" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
        </div>
        <div class="nomeLocal">{{tarefa.espa_nome}}</div>
      </div>

      <div class="erro" v-if="nrTarefasPendentes > 0">
          <span>{{nrTarefasPendentes}} tarefas(s) pendentes(s)</span>
          <button class="botao botaoOk" @click="enviarAoServidor()">atualizar</button>
      </div>

      <audio id="playerSom" style="display: none;">
        <source src="static/som/click.mp3" type="audio/mpeg">
      </audio>

      <div class="caixaConteudoBaixo" v-if="tarefa.espa_lota">
        <button @click="subtrair()" class="botao" style="background-image: url('static/img/menos.svg')"></button>
        <button @click="somar()" class="botao" style="background-image: url('static/img/mais.svg')"></button>
      </div>
    </div>


  </div>
</template>


<script>

  import MenuUtilizador from '@/components/MenuUtilizador'
  import contadorService from '@/services/contador'
  import utilizadoresService from '@/services/utilizadores'
  import UIDGenerator from 'uid-generator';
  import lsService from '@/services/localstorage'

export default {
  name: 'Contador',
  components: {
    MenuUtilizador
  },
  data: function () {
    return {
        "corCasa": '',
        "tarefa": {},
        "nrTarefasPendentes": 0,
        "timer": 1000,
        "intervalObject": null
    }
  },
  methods: {

    calcularCor: function (pct) {

      let that = this;

      var percentColors = [
        { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
        { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
        { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } }
        ];

      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break;
        }
      }
      var lower = percentColors[i - 1];
      var upper = percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };

      that.corCasa = 'rgb(' + [color.r, color.g, color.b].join(',') + ')';

    },
    somar: function(){
      let that = this;
      that.tarefa.ocupacao += 1;
      that.calcularPercentagem();
      that.enviarAoServidor(1);
    },
    subtrair: function(){
      let that = this;
      that.tarefa.ocupacao -= 1;

      if(that.tarefa.ocupacao < 0){
        that.tarefa.ocupacao = 0;
      }

      that.calcularPercentagem();
      that.enviarAoServidor(-1);

    },
    calcularPercentagem: function(){
      let that = this;

      var percentagem = that.tarefa.ocupacao / that.tarefa.espa_lota;
      that.calcularCor(percentagem)

    },
    formatarInteiros: function(inteiro){

      if(inteiro){
        return inteiro.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }else{
        return 0;
      }

    },
    pedirColocacaoDoUtilizador: function(){

      let that = this;

      contadorService.pedirColocacaoDoUtilizador()
              .then(function(resposta){

                if(resposta['estado'] == 'erro'){
                  that.$wkToast(resposta['mensagens'],
                          {
                            className: ['wk-alert'],
                          }
                  );
                  utilizadoresService.logout();
                  that.$router.push('/');
                  return;
                }

                if(resposta['estado'] == 'ok'){
                  that.tarefa = resposta['dados'];
                }

              })
    },
    enviarAoServidor: function(numero){
      let that = this;
      const uidgen = new UIDGenerator();
      that.tarefa['alteracao'] = numero;

      // Play do som
      var player = document.getElementById('playerSom');
      player.load();
      player.play();


      clearInterval(that.intervalObject);
      that.timerVerificarAtualizacoes();

      // O UUID retorna uma promisse
      let UUIDEnvio = ''
      uidgen.generate()
              .then(function(uuid){
                UUIDEnvio = uuid;
                that.tarefa['uuid'] = UUIDEnvio;

                // Grava este registo no localStorage
                lsService.escreverTarefas(that.tarefa);
                // Agora lê o localstorage e tenta enviar todas as que existam
                // Pode ter sido gravadas quando estava offline
                let tarefasParaABd = lsService.lerTarefas();

                let nrTarefasPendentes = tarefasParaABd.length;

                for(let i = 0; i < nrTarefasPendentes; i++){
                  let estaTarefa = tarefasParaABd[i];
                  // Para cada tarefa pendente envio um pedido ao servidor
                  contadorService.gravarRegisto(estaTarefa)
                          .then(function(uuid){
                            lsService.removerTarefa(uuid);

                            // if(UUIDEnvio !== uuid){
                              that.verificarSeHaRegistosPendentes();
                            // }


                          })
                  //console.log(estaTarefa);
                }

              })

    },
    verificarSeHaRegistosPendentes: function(){
      let tarefasPendentes = lsService.lerTarefas();
      this.nrTarefasPendentes = tarefasPendentes.length;
    },
    timerVerificarAtualizacoes: function(){
      let that = this;
      that.intervalObject = setInterval(function(){
        that.pedirColocacaoDoUtilizador();
        that.calcularPercentagem();
      }, that.timer)
    }

  },
  mounted: function(){

    let that = this;
    that.calcularCor(0);
    that.pedirColocacaoDoUtilizador();
    that.verificarSeHaRegistosPendentes();
    that.timerVerificarAtualizacoes();

    // document.getElementById('app').requestFullscreen();
    document.documentElement.requestFullscreen();

  },
  beforeUnmount: function(){
    var that = this;
    clearInterval(that.intervalObject);
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .caixaContador{
    position: relative;
    min-height: 100vh;
  }

  .caixaContador{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    margin: 0 auto;
  }

  .icon{
    display: flex;
    justify-content: center;
    padding-top: 60px;
    position: relative;
    svg{
      max-width: 250px;
      transition: fill 0.25s;
    }
  }

  .nomeLocal{
    color: white;
    max-width: 95%;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
  }

  .caixaContadorCima{

  }

  .caixaConteudoBaixo{
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    button{
      flex-basis: 48%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 64px 64px;
      background-color: $corEscura;
      cursor: pointer;
      &:after{
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  .contagem{
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .contagemNoEspaco{
      font-size: 32px;
      font-weight: bold;
  }

  .contagemNoLotacao{
    font-size: 24px;
  }

  .erro{
    background-color: $corErro;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      padding-right: 5px;
    }
    button{
      margin-left: 5px;
    }
  }


</style>