import lsService from '@/services/localstorage'

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

import axios from 'axios'

export default {
    pedirTudo: function(dados){
        let params = {
            "accao" : "pedirTudo",
            "token": lsService.ler(),
            "dados": JSON.stringify(dados),
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'controlos.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    },
    gravar: function(dadosGravar, data_iden, espa_iden){

        // Quando se tenta apaga o ultimo registo
        // Os dados gravar não são enviados
        // Porque o array vai vazio
        // Por isso, leva a data para saber qual a data que fica vazia e apagar

        var formData = new FormData;

        formData.append("accao", "gravar");
        formData.append("dados", JSON.stringify(dadosGravar));
        formData.append("data_iden", data_iden);
        formData.append("espa_iden", espa_iden);
        formData.append("token", lsService.ler());

        let ajax = axios.post(apiEndpoint + 'controlos.php?rnd=' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;

    },
    apagar: function(dadosApagar){

        var formData = new FormData;

        formData.append("accao", "apagar");
        formData.append("dados", JSON.stringify(dadosApagar));
        formData.append("token", lsService.ler());

        let ajax = axios.post(apiEndpoint + 'controlos.php?rnd=' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;

    },
    pedirControlosParaUmaData: function(data_iden){
        let params = {
            "accao" : "pedirControlosParaUmaData",
            "token": lsService.ler(),
            "data_iden": data_iden,
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'controlos.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    },
    verificaOutroControloParaOMesmoUtilizadorNoutroEspacoNaMesmaData(dados){
        let params = {
            "accao" : "verificaOutroControloParaOMesmoUtilizadorNoutroEspacoNaMesmaData",
            "token": lsService.ler(),
            "dados": dados,
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'controlos.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    }
}