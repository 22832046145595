import { createApp } from 'vue'
import Root from './App.vue'
import router from './router'

import Toast from 'vue3-toast-single'
import 'vue3-toast-single/dist/toast.css'

import { reactive } from 'vue'


import mitt from 'mitt';
const emitter = mitt();

const app = createApp(Root);
app.use(router);
app.use(Toast, { verticalPosition: "bottom", duration: 15000 });


const store = reactive({
    mensagem: "",
    timeout: 15000
})


app.config.globalProperties.emitter = emitter;
app.config.globalProperties.store = store;

app.mount('#app');
