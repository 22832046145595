<template>
    <div class="wait">


    </div>
</template>


<script>


    export default {
        name: 'Wait',
        components: {

        },
        data: function () {
            return {
            }
        },
        methods: {


        },
        mounted: function(){


        },
        beforeUnmount: function(){
        },
        watch: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .wait{
        /*position: fixed;*/
        /*left: 0;*/
        /*top: 0;*/
        /*right: 0;*/
        height: 15px;
        background-image:
                repeating-linear-gradient(
                                -45deg,
                                white,
                                white 1rem,
                                $corPrincipal 1rem,
                                $corPrincipal 2rem
                );
        background-size: 200% 200%;
        animation: barberpole 10s linear infinite;
        /*display: none;*/
    }

    @keyframes barberpole {
        100% {
            background-position: 100% 100%;
        }
    }


</style>