const nomeChave        = 'headcount';
const nomeChaveTarefas = 'headcountTarefas';

export default {
    gravar: function(valor){
        localStorage.setItem(nomeChave, valor);
    },
    ler: function(){
        return localStorage.getItem(nomeChave);
    },
    removerToken: function(){
        localStorage.removeItem(nomeChave);
    },
    lerTarefas: function(){

        let tarefasAnteriores = localStorage.getItem(nomeChaveTarefas);
        let json              = JSON.parse(tarefasAnteriores);

        if(!json){
            return [];
        }

        return json;

    },
    escreverTarefas: function(tarefa){
        let tarefasAnteriores = this.lerTarefas();
        tarefasAnteriores.push(tarefa);

        let nrTarefas = tarefasAnteriores.length;

        let unique = [];
        for(var i = 0; i < nrTarefas; i++){
            if(tarefasAnteriores[i].alteracao){
                unique.push(tarefasAnteriores[i]);
            }
        }

        localStorage.setItem(nomeChaveTarefas, JSON.stringify(unique));
    },
    removerTarefa: function(uuid){
        let tarefasAnteriores = this.lerTarefas();
        let nrTarefas = tarefasAnteriores.length;

        let tarefasPendentes = [];

        for(let i = 0; i < nrTarefas; i++){
            if(tarefasAnteriores[i].uuid !== uuid){
                tarefasPendentes.push(tarefasAnteriores[i]);
            }
        }

        localStorage.setItem(nomeChaveTarefas, JSON.stringify(tarefasPendentes));

    }
}