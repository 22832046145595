<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador></MenuAdministrador>

    <div class="controlos">

      <div class="controlosPesquisa">

        <div class="controlosPesquisaDatasEspacos">
          <div class="datas">
            <div class="umaData"
                 :class="{'selecionado': data.data_iden === dadosSelecionados.data.data_iden}"
                 v-for="(data, i) in todasAsDatas" :key="i"
                 @click="selecionarData(data)"
                >{{data.data_data}}</div>
          </div>
          <div class="espacos">
            <div class="umEspaco"
                 :class="{'selecionado': espaco.espa_iden === dadosSelecionados.espaco.espa_iden}"
                 v-for="(espaco, i) in todosOsEspacos" :key="i"
                 @click="selecionarEspaco(espaco)"
              >{{espaco.espa_nome}}</div>
          </div>
        </div>

        <div class="controlosPesquisaUtilizadores">
          <span
                  v-for="(user, i) in todosOsUtilizadores"
                  :key="i"
                  @click="selecionarControlador(user)"
              >
            <div class="umUtilizador"
                 :class="{'selecionado': user.util_iden === dadosSelecionados.controlador.util_iden}"
                 v-if="esconderUtilizador(user)"
            >
            <div class="umUtilizadorEstado" :class="{'umUtilizadorEstadoAtivo': user.util_acti == 1}">
              <span></span>
            </div>
            <div>{{user.util_nome}}</div>
            <div>{{user.util_emai}}</div>
            <div>{{user.util_tele}}</div>
          </div>
          </span>

        </div>

        <div class="controloPesquisaBotao">
          <button class="botao botaoComRebordo botaoTransparente"
                v-if="dadosSelecionados.data.data_iden !== 0 && dadosSelecionados.espaco.espa_iden !== 0 && dadosSelecionados.controlador.util_iden !== 0"
                @click="adicionar()"
          >adicionar</button>
        </div>

      </div>

      <div class="controloLegenda">
        <div style="display: flex;">
          <div class="controloLegendaLocal">{{dadosSelecionados.espaco.espa_nome}}</div>
          <div class="controloLegendaData">{{dadosSelecionados.data.data_data}}</div>
        </div>
        <div>
          <button class="botao botaoApagar"
                  v-if="dadosSelecionados.data.data_iden !== 0 && dadosSelecionados.espaco.espa_iden !== 0"
                  @click="apagar()"
            >apagar todos os registos deste espaço e data</button>
          &nbsp;
          <button class="botao botaoTransparente botaoComRebordo"
            v-if="verBotaoGravar"
                  @click="gravar()"
            >gravar</button>
        </div>

      </div>

<!--      <pre>{{controladores}}</pre>-->
      <div class="controladores">
            <div class="umControlador" v-for="(cont, i) in controladores" :key="i">
              <div class="umUtilizadorEstado"
                   :class="{'umUtilizadorEstadoAtivo':cont.util_acti == 1}"
                >
                <span></span>
              </div>
              <div>{{cont.cont_json.utilizador.util_nome}}</div>
              <div>{{cont.cont_json.utilizador.util_emai}}</div>
              <div>{{cont.cont_json.utilizador.util_tele}}</div>
              <div>
                <button class="botao botaoApagar"
                    @click="remover(i)"
                    >remover</button>
              </div>
            </div>
      </div>

    </div>

  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'
  import datasService from '@/services/datas'
  import espacosService from '@/services/espacos'
  import utilizadoresService from '@/services/utilizadores'
  import controlosService from '@/services/controlos'


  export default {
  name: 'Controlos',
  components: {
    MenuAdministrador
  },
  data: function () {
    return {
      "todasAsDatas": [],
      "todosOsEspacos": [],
      "todosOsUtilizadores": [],
      "dadosSelecionados":{
        "espaco": {
          "espa_iden": 0,
          "espa_nome": ""
        },
        "data": {
          "data_iden": 0,
          "data_data": ""
        },
        "controlador": {
          "util_iden": 0,
          "util_nome": ""
        }
      },
      "controladores": [],
      "verBotaoGravar": false
    }
  },
  methods: {

    pedirTodasAsDatas: function(){

      let that = this;
      that.todasAsDatas = [];

      datasService.pedirTudo()
              .then(function(datas){
                  datas.forEach(function(data){
                    that.todasAsDatas.push(data)
                  })
              })

    },
    pedirTodosOsEspacos: function(){

      let that = this;
      that.todosOsEspacos = [];

      espacosService.pedirTudo()
              .then(function(datas){
                datas.forEach(function(data){
                  that.todosOsEspacos.push(data)
                })
              })
    },
    pedirTipoUtilAtivos: function(){

      let that = this;
      that.todosOsUtilizadores = [];

      utilizadoresService.pedirTipoUtil()
              .then(function(datas){
                datas.forEach(function(data){
                  that.todosOsUtilizadores.push(data)
                })
              })

    },
    selecionarData: function(data){
        let that = this;
        that.dadosSelecionados.data.data_iden = data.data_iden;
        that.dadosSelecionados.data.data_data = data.data_data;
    },
    selecionarEspaco: function(espaco){
      let that = this;
      that.dadosSelecionados.espaco.espa_iden = espaco.espa_iden;
      that.dadosSelecionados.espaco.espa_nome = espaco.espa_nome;
    },
    selecionarControlador: function(user){
      let that = this;
      that.dadosSelecionados.controlador.util_iden = user.util_iden;
      that.dadosSelecionados.controlador.util_nome = user.util_nome;
      that.dadosSelecionados.controlador.util_acti = user.util_acti;

    },
    adicionar: function(){
      let that = this;

      // Verifica se o mesmo utilizador já tem alguma marcação para o mesmo dia
      // Se tiver indica onde


      controlosService.verificaOutroControloParaOMesmoUtilizadorNoutroEspacoNaMesmaData(that.dadosSelecionados)
              .then(function(resposta){
                if(resposta.estaNoutroEspaco){
                  that.$wkToast('O utillizador já está alocado a: ' + resposta.estaNoutroEspaco,
                          {
                            className: ['wk-alert'],
                          }
                  );
                }else{
                  let novo = {
                    "cont_iden": "0",
                    "cont_espa": that.dadosSelecionados.espaco.espa_iden,
                    "cont_util": that.dadosSelecionados.controlador.util_iden,
                    "cont_data": that.dadosSelecionados.data.data_iden,
                    "cont_json": {
                      "data": that.dadosSelecionados.data,
                      "espaco": that.dadosSelecionados.esapco,
                      "utilizador": that.dadosSelecionados.controlador
                    },
                    "util_acti": that.dadosSelecionados.controlador.util_acti
                  };


                  let string      = JSON.stringify(novo);
                  let novoInserir = JSON.parse(string);

                  that.controladores.unshift(novoInserir)

                  that.verBotaoGravar = true
                }
              })




    },
    pesquisar: function(){

      let that = this;

      // Apenas pede dados se existirem os critérios necessários
      if(that.dadosSelecionados.data.data_iden !== 0 && that.dadosSelecionados.espaco.espa_iden !== 0){
        controlosService.pedirTudo(that.dadosSelecionados)
                .then(function(resposta){
                  that.controladores = resposta;
                  that.$forceUpdate();
                })
      }

    },
    remover: function(indice){
      this.controladores.splice(indice, 1);
      // if(this.controladores.length > 0){
        this.verBotaoGravar = true
      // }else{
      //   this.verBotaoGravar = false
      // }

    },
    esconderUtilizador: function(user){

      let that = this;
      let mostrar = true;


        that.controladores.forEach(function(item){
          if(item.cont_util === user.util_iden){
            mostrar = false;
          }
        });

      return mostrar;

    },
    gravar: function(){
        let that = this;

        console.log(that.dadosSelecionados.espaco.espa_iden);

        controlosService.gravar(that.controladores, that.dadosSelecionados.data.data_iden, that.dadosSelecionados.espaco.espa_iden)
                .then(function(){
                  that.pesquisar();
                  setTimeout(function(){
                    that.$wkToast('Dados gravados');
                  }, 0)
                })

      this.verBotaoGravar = false

    },
    apagar: function(){
      let that = this;

      controlosService.apagar(that.dadosSelecionados)
              .then(function(){
                  that.pesquisar();
                  setTimeout(function(){
                    that.$wkToast('Dados gravados');
                  }, 0)
              })
      this.verBotaoGravar = false
    }

  },
  mounted: function(){

    let that = this;
    that.pedirTodasAsDatas();
    that.pedirTodosOsEspacos();
    that.pedirTipoUtilAtivos();

  },
  watch: {
    // Se mudar o espaco ou a data faz nova pesquisa
    // Se mudar o utilizador não faz
    "dadosSelecionados.espaco.espa_iden": function(){
        this.pesquisar();
    },
    "dadosSelecionados.data.data_iden": function(){
      this.pesquisar();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .controlos{
      @include cintas($larguraDefault);
      padding-top: 50px;
    }

  .controlosPesquisa{
    padding: 20px;
    background-color: $corEscura;
    color: white;
    display: grid;
    grid-template-columns: 0.5fr 1fr 150px;
    grid-gap: 15px;
    height: 50vh;
  }

    .controlosPesquisaDatasEspacos{
      /*display: flex;*/
      /*flex-direction:column;*/
      /*flex-wrap: wrap;*/
      /*max-height: 50vh;*/
      border-right: 1px solid white;
    }

    .datas{
      /*height: 50%;*/
      max-height: 50%;
      overflow: auto;
      /*display: flex;*/
      /*flex-wrap: wrap;*/
      /*align-items: center;*/
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      padding-right: 15px;
      border-bottom: 15px solid transparent;
    }

    .espacos{
      border-top: 15px solid transparent;
      height: 50%;
      max-height: 50%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .umaData{
      border: 1px solid white;
      padding: 10px;
      /*margin: 10px;*/
      cursor: pointer;
      text-align: center;
    }

    .umEspaco{
      border: 1px solid white;
      padding: 10px;
      margin: 10px;
      cursor: pointer;
    }

    .controlosPesquisaUtilizadores{
      max-height: 50vh;
      overflow: auto;
      border-right: 1px solid white;
    }

    .umUtilizador{
      display: grid;
      grid-template-columns: 24px 1fr 0.75fr 100px;
      grid-gap: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      &:nth-child(even){
        background-color: rgba(white, 0.2);
      }
    }

    .umUtilizadorEstado{
      display: flex;
      align-items: center;
      width: 24px;
      span{
        background-color: $corErro;
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }

    .umUtilizadorEstadoAtivo{
      span{
        background-color: $corOk;
      }
    }

    .controloPesquisaBotao{
        align-self: center;
        text-align: center;
    }

  .controloLegenda{
    background-color: $corEscura;
    color: white;
    margin-top: 5px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
  }

    .controloLegendaLocal{
      margin-right: 15px;
    }

    .controloLegendaData{
      margin-left: 15px;
    }

  .selecionado{
    background-color: white;
    color: $corEscura;
  }

  .controladores{
    @include cintas($larguraDefault / 2);
    margin-top: 20px;
    background-color: $corEscura;
    color: white;
  }

  .umControlador{
    display: grid;
    grid-template-columns: 24px 0.5fr 0.5fr 150px 100px;
    align-items: center;
    padding: 15px;
    &:nth-child(even){
      background-color: rgba(white, 0.2);
    }
  }

</style>