<template>
  <router-view/>
</template>

<style lang="scss">

#app {
  min-height: 100vh;
}

</style>
