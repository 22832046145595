<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador></MenuAdministrador>

    <div class="dashboard">

      <div class="dashboardItem dashboardItemListagem">
      <button @click="listagemGeral()">listagem geral</button>
        <Wait v-show="verWait"></Wait>
        <a href="./static/csv/listagem.csv">Download</a>
      </div>

      <div class="dashboardItem">

        <div class="utilizadores" @click="mostrarCalendario()" style="background-image: url('static/img/calendario.svg')"></div>

        <div class="listaUtilizadores" id="calendario">
          <div class="listaUtilizadoresFechar" @click="fecharCalendario(i)"><span>&times;</span></div>
          <div class="nomeDoEspaco">
            Escolha uma data
          </div>

          <div class="listaDeDatas">
              <div class="umaData"
                   :class="{'dataSelecionada': dataAtiva.data_iden === data.data_iden}"
                   v-for="(data, i) in todasAsDatas" :key="i">
                  <div @click="escolherData(data)">{{data.data_data}}</div>
              </div>
          </div>

        </div>

        <div class="dashboardIcon">
          <div class="contagem" v-if="totais">
            <div class="noEspaco" v-html="formatarInteiros(totais.lotacao)" style="color: white;"></div>
            <div class="lotacao" v-html="formatarInteiros(totais.ocupacao)"></div>
          </div>
          <!--          <svg class="w-6 h-6" :fill="corCasa" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>-->
        </div>
        <div class="nomeDoEspaco">
          Ocupação geral
        </div>
        <div class="grafico" v-if="espacos">
          <canvas :id="'grafico_' + espacos.length"></canvas>
        </div>
      </div>

        <div class="dashboardItem" v-for="(espaco, i) in espacos" :key="i" :style="{'background-color': calcularCor(espaco.ocupacao / espaco.espa_lota) }">


          <div class="listaUtilizadores" :id="'listaUtilizadores_' + i">
            <div class="listaUtilizadoresFechar" @click="fecharListaUtilizadores(i)"><span>&times;</span></div>
            <div class="nomeDoEspaco" style="margin-bottom: 20px; font-size: 24px;">{{espaco.espa_nome}}</div>
            <div class="listaUtilizadoresItem" v-for="(user, i) in espaco.utilizadores" :key="i">
              <div class="listaUtilizadoresItemNome">{{user.util_nome}}</div>
              <a :href="'tel:' + user.util_tele" class="listaUtilizadoresItemTelefone">
                <span>{{user.util_tele}}</span>
                <svg class="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
              </a>
            </div>

          </div>


          <div class="utilizadores" @click="mostrarUtilizadores(i)" style="background-image: url('static/img/utilizadores.svg')"></div>

          <div class="dashboardIcon">
            <div class="contagem">
              <div class="noEspaco" v-html="formatarInteiros(espaco.espa_lota)"></div>
              <div class="lotacao" v-html="formatarInteiros(espaco.ocupacao)"></div>
            </div>
            <!--          <svg class="w-6 h-6" :fill="corCasa" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>-->
          </div>
          <div class="nomeDoEspaco">
            {{espaco.espa_nome}}
          </div>
          <div class="grafico">
            <canvas :id="'grafico_' + i" data-grafico="espaco"></canvas>
          </div>
        </div>

    </div>


    <table id="listagem">
      <tr>
        <th>Data</th>
        <th>Espaço</th>
        <th>Utilizador</th>
        <th>Lotação</th>
        <th>Movimento</th>
      </tr>
      <tr v-for="(linha, i) in listagem" :key="i">
        <td>{{linha.data}}</td>
        <td>{{linha.espaco}}</td>
        <td>{{linha.utilizador}}</td>
        <td>{{linha.lotacao}}</td>
        <td>{{linha.movimento}}</td>
      </tr>
    </table>

  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'
  import Wait from '@/components/Wait'
  //https://www.chartjs.org/docs/latest/
  import Chart from 'chart.js/auto';

  import datasService from '@/services/datas'
  import dashboardServices from '@/services/dashboard'

  // import TableToExcel from 'table-to-excel';


  export default {
  name: 'Contador',
  components: {
    MenuAdministrador, Wait
  },
  data: function () {
    return {
      "todasAsDatas": [],
      "dataAtiva": {},
      "espacos": [],
      "totais": {},
      "graficos": [],
      "listagem": [],
      "intervals": [],
      "verWait": false
    }
  },
  methods: {

    calcularCor: function (pct) {

      var percentColors = [
        { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
        { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
        { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } }
        ];

      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break;
        }
      }
      var lower = percentColors[i - 1];
      var upper = percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };

      return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 0.9)';

    },
    calcularPercentagem: function(){
      let that = this;

      var percentagem = that.numeros.noEspaco / that.numeros.lotacao;
      that.calcularCor(percentagem)

    },
    gerarMapa: function(id, dados){

      let that = this;

      if(!dados){
        return;
      }

      const labels = dados.hora;
      const data = {
        labels: labels,
        datasets: [{
          label: 'Ocupação',
          data: dados.ocupacao,
          fill: true,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      };

      const config = {
        type: 'line',
        data: data,
      };


        var ctx = document.getElementById('grafico_' + id);

        let grafico = new Chart(ctx, {
          type: 'line',
          data: data,
          config: config,
          options : {
            plugins: {
              legend: false,
              responsive: true
            },
            animation: false
          }
        });

        that.graficos.push(grafico);

    },
    formatarInteiros: function(inteiro){
      if(!inteiro){
        return 0;
      }
      return inteiro.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    mostrarUtilizadores: function(indice){
      document.getElementById('listaUtilizadores_' + indice).style.display = 'block';
    },
    fecharListaUtilizadores: function(indice){
      document.getElementById('listaUtilizadores_' + indice).style.display = 'none';
    },
    mostrarCalendario: function(){
      document.getElementById('calendario').style.display = 'block';
    },
    fecharCalendario: function(){
      document.getElementById('calendario').style.display = 'none';
    },
    pedirDadosParaAData: function(){
      let that = this;
      that.graficos = [];

      dashboardServices.pedirDadosDashboard(that.dataAtiva)
              .then(function(resposta){
                that.espacos = resposta.espacos;

                that.totais = resposta.totais;




              })
    },
    escolherData: function(data){
        let that = this;
        that.dataAtiva = data;
        // Tem que remover todos os canvas
        // let cnvs = document.querySelectorAll('canvas');
        // that.pedirDadosParaAData();

      that.pedirDadosParaAData();

    },
    listagemGeral: function(){
      let that = this;

      that.verWait = true;
      document.querySelector('.dashboardItemListagem a').style.display = 'none';

        dashboardServices.listagemGeral()
                .then(function(){
                  document.querySelector('.dashboardItemListagem a').style.display = 'flex';

                  that.verWait = false;

                  // that.listagem = resposta.dados;
                  //
                  // that.$nextTick(function(){
                  //   let tableToExcel = new TableToExcel();
                  //   tableToExcel.render("listagem");
                  // })

                });
    },
    pedirTudo: function(){
      let that = this;

      // Vê qual a data ativa
      datasService.pedirTudo()
              .then(function(todasAsDatas){
                let nrDatas = todasAsDatas.length;

                that.todasAsDatas = todasAsDatas;

                for(let i = 0; i < nrDatas; i++){


                  if(todasAsDatas[i].data_acti == 1 && !that.dataAtiva.data_iden){
                    that.dataAtiva = todasAsDatas[i];
                  }
                }

                that.pedirDadosParaAData();

              })


      that.calcularCor(0);
    },
    criarGraficos: function(){

      let that = this;

      for (var key in Chart.instances) {
        Chart.instances[key].destroy();
      }

      that.$nextTick(function(){
        // Gera os gráficos dos espaços
        var cnvs = document.querySelectorAll('[data-grafico="espaco"]')
        var nrCanvas = cnvs.length;

        for(var i = 0; i < nrCanvas; i++){
          that.gerarMapa(i, that.espacos[i]['grafico']);
        }

        // Gera gráfico dos totais
        that.gerarMapa(cnvs.length, that.totais.grafico);

      })

    }

  },
  mounted: function(){

    let that = this;

    that.intervals['pedirDados'] = setInterval(function(){
      that.pedirTudo();
    }, 2000);

    that.intervals['criarGraficos'] = setInterval(function(){
      that.criarGraficos();
    }, 2000);

  },
    beforeUnmount: function(){
    var that = this;
        clearInterval(that.intervals['pedirDados']);
        clearInterval(that.intervals['criarGraficos']);
    },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .dashboard{
    @include cintas($larguraDefault);
    position: relative;
    padding-top: 80px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
  }

  canvas{
    max-width: 100%;
  }

  .dashboardItem{
    background-color: $corEscura;
    padding: 20px;
    position: relative;
    /*max-width: 477px;*/
  }

  .dashboardIcon{
    position: relative;
    max-width: 350px;
    margin: 0 auto;
  }

  .contagem{
    /*position: absolute;*/
    text-align: center;
    color: $corPrincipal;
    margin-bottom: 30px;
  }

  .noEspaco{
      font-size: 50px;
      color: $corEscura;
  }

  .lotacao{
      font-size: 24px;
  }


  .nomeDoEspaco{
    color: rgba(white, 0.8);
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }

  .utilizadores{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: $corEscura;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    z-index: 3;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    box-shadow: 2px 2px 5px 0px rgba(255,255,255,0.75);
  }

  .listaUtilizadores{
    background-color: $corEscura;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    color: white;
    overflow: auto;
    display: none;
  }

  .listaUtilizadoresFechar{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    background-color: $corEscura;
    top: 0;
    span{
      padding: 15px;
      cursor: pointer;
    }
  }

  .listaUtilizadoresItem{
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(white, 0.2);
    &:nth-last-child(1){
      margin-bottom: 50px;
    }
  }

  .listaUtilizadoresItemNome{
    padding-top: 15px;
    padding-bottom: 15px;
    flex-grow: 1;
  }

  .listaUtilizadoresItemTelefone{
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    svg{
      margin-left: 10px;
      max-height: 16px;
    }
  }

  .listaDeDatas{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
  }

  .umaData{
    border: 1px solid white;
    font-weight: bold;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.25s;
    &:hover{
      background-color: white;
      color: $corPrincipal;
      transition: all 0.25s;
    }
  }

  .dataSelecionada{
    background-color: white;
    color: $corEscura;
  }

  #listagem{
    display: none;
  }

  .dashboardItemListagem{
    display: flex;
    flex-direction: column;
  }

  .dashboardItemListagem button, a{
      display: block;
      flex-grow: 1;
  }

  .dashboardItemListagem button{
      width: 100%;
      padding: 20px;
  }

  .dashboardItemListagem a{
    margin-top: 15px;
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: white;
    /*display: flex;*/
    display: none;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
  }


  @media only screen and (max-width: 1100px) {
    .dashboard{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 850px) {

    .dashboard{
      grid-template-columns: repeat(2, 1fr);
    }

    .grafico{
      display: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .dashboard{
      grid-template-columns: 1fr;
    }
  }


</style>