<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">
    <MenuAdministrador></MenuAdministrador>

<!--      <pre style="position: relative; z-index: 5; padding-top: 100px;">{{espacos}}</pre>-->
    <div class="espacos">
      <div class="espacosTitulo">Nome do espaço</div>
      <div class="espacosTitulo">Lotação</div>
      <div class="espacosTitulo" style="text-align: center;">Ativo</div>
      <div class="espacosTitulo"></div>
      <div class="espacosTitulo">
        <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                @click="novo()"
        >adicionar</button>
      </div>
    </div>

    <div class="espacosDados" v-for="(espaco, i) in espacos" :key="i">
          <div class="espacosValores">
          <input type="text" v-model="espaco.espa_nome" />
        </div>
        <div class="espacosValores" style="text-align: center;">
          <input type="number" v-model="espaco.espa_lota"  />
        </div>
        <div class="espacosValores" style="text-align: center;">
          <input type="checkbox" v-model="espaco.espa_acti" />
        </div>
        <div class="espacosValores">
          <button class="botao botaoTransparente botaoComRebordo botaoApagar" style="color: white;"
                  @click="apagar(espaco, i)"
              >apagar</button>
        </div>
        <div class="espacosValores">
          <button class="botao botaoTransparente botaoComRebordo" style="color: white;"
                  @click="gravar(espaco)"
              >gravar</button>
        </div>
      </div>




  </div>
</template>


<script>

  import MenuAdministrador from '@/components/MenuAdministrador'
  import espacosService from '@/services/espacos'

  export default {
    name: 'Espacos',
    components: {
      MenuAdministrador
    },
    data: function () {
      return {
        "espacos": [],
          "teste": ""
      }
    },
    methods: {

        todosOsEspacos: function(){
            let that = this;

            espacosService.pedirTudo()
                .then(function(resposta){

                    let nrEspacos = resposta.length;


                    for(let i = 0; i < nrEspacos; i++){

                        if(resposta[i].espa_acti == 1){
                            resposta[i].espa_acti = true;
                        }else{
                            resposta[i].espa_acti = false;
                        }
                    }

                    that.espacos = resposta;
                })

        },
        novo: function(){
            let that = this;

            let novo = {
                "espa_iden": 0,
                "espa_nome": '',
                "espa_lota": 0,
                "espa_acti": true
            }

            let stringJson = JSON.stringify(novo);
            that.espacos.unshift(JSON.parse(stringJson))

        },
        gravar: function(espaco){

            let that = this;

            // Valida se tem nome
            if(espaco.espa_nome.trim().length < 2){
                that.$wkToast('Tem que indicar um nome!',
                    {
                        className: ['wk-alert'],
                    }
                );
                return;
            }else{
                espacosService.gravar(espaco).
                    then(function(resposta){

                        if(resposta['estado'] === 'ok'){
                            that.todosOsEspacos();
                            setTimeout(function(){
                                that.$wkToast('Registo gravado.');
                            }, 0)
                        }

                    if(resposta['estado'] === 'erro'){
                        that.$wkToast(resposta.mensagens,
                            {
                                className: ['wk-alert'],
                            }
                        );
                    }

                })
            }
        },
        apagar: function(registo, i){

            let that = this;

            if(registo.espa_iden === 0){
                that.espacos.splice(i, 1);
                return;
            }

            var confirmacao = confirm('Confirma que quer apagar este registo?');

            if(confirmacao){
                espacosService.apagar(registo)
                    .then(function(resposta){

                        if(resposta['estado'] === 'ok'){
                            that.todosOsEspacos();
                            setTimeout(function(){
                                that.$wkToast('Registo apagado.');
                            }, 0)
                        }

                        if(resposta['estado'] === 'erro'){
                            that.$wkToast(resposta.mensagens,
                                {
                                    className: ['wk-alert'],
                                }
                            );
                        }

                    })
            }

        }

    },
    mounted: function(){

        this.todosOsEspacos();

    },
    watch: {

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .espacos{
    @include cintas($larguraDefault / 2);
    position: relative;
    z-index: 5;
    padding-top: 100px;
    background-color: $corPrincipal;
    color: white;
    display: grid;
    grid-template-columns: 1fr 100px 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
  }

  .espacosTitulo{
    font-weight: bold;
    padding-bottom: 20px;
  }

  .espacosDados{
    @include cintas($larguraDefault / 2);
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr 100px 100px 100px 100px;
    grid-gap: 10px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $corEscura;
  }


</style>