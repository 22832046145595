<template>
  <div class="fundo" style="background-image: url('static/img/fundo.jpg')">

      <div class="loginCaixa">
        <h1>Login</h1>
          <label class="labelComIcon">
            <input type="email" v-model="utilizador.utilizador" autofocus />
<!--            <input type="text" placeholder="utilizador" v-model="utilizador.utilizador" />-->
            <svg class="w-6 h-6" fill="#1D6787" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
          </label>
          <label  class="labelComIcon">
            <input type="password" placeholder="password" v-model="utilizador.password" />
            <svg class="w-6 h-6" fill="#1D6787" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd"></path></svg>
          </label>
        <div class="caixaBotoes">
          <button class="botao botaoTransparente"
            @click="fazerLogin()"
            >entrar</button>
        </div>
        <div class="logo" style="background-image: url('static/img/logo.svg')"></div>
      </div>

  </div>
</template>


<script>

import utilizadoresService from '@/services/utilizadores'
import lsService from '@/services/localstorage'

export default {
  name: 'Login',
  components: {

  },
  data: function () {
    return {
      "utilizador": {
        "utilizador": "",
        "password": ""
      }
    }
  },
  methods: {

    fazerLogin: function(){

      let that = this;

        utilizadoresService.fazerLogin(this.utilizador)
                .then(function(resposta){

                  if(resposta['estado'] == 'erro'){
                    that.$wkToast(resposta['mensagens'],
                            {
                              className: ['wk-alert'],
                            }
                    );
                    return;
                  }

                  if(resposta['estado'] == 'ok'){
                    // Grava na localstorage
                    lsService.gravar(resposta['dados']['util_toke']);
                    that.paraOnde(resposta['dados']['util_func']);
                    return;
                  }

                  // Ocorreu outro erro
                  that.$wkToast('Ocorreu um erro ao fazer login. Verifique se está ligado à rede.',
                          {
                            className: ['wk-alert'],
                          }
                  );

                })
    },
    paraOnde: function(util_func){
      let that = this;

      switch (util_func) {
        case 'admin':
          that.$router.push('/dashboard');
          break;
        case 'util':
          that.$router.push('/contador')
          break;
      }


    }

  },
  mounted: function(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .loginCaixa{
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    color: black;
    width: 90%;
    max-width: 350px;
    background-color: rgba(white, 0.9);
    padding: 15px;
    border-radius: 5px;
    h1{
      font-weight: 300;
      margin-top: 0;
    }
  }

  .caixaBotoes{
    padding: 10px;
  }

  .botaoTransparente{
    color: white;
    border: 1px solid white;
    background-color: black;
  }

  .logo{
    /*width: 80px;*/
    max-height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    &:after{
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

</style>