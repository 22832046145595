import lsService from '@/services/localstorage'

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

import axios from 'axios'

export default {

    pedirDadosDashboard: function(data){
        let params = {
            "accao" : "pedirDadosDashboard",
            "dados": JSON.stringify(data),
            "token": lsService.ler(),
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'dashboard.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    },
    listagemGeral: function(){
        let params = {
            "accao" : "listagemGeral",
            "token": lsService.ler(),
            "rnd": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        };
        let ajax = axios.get(apiEndpoint + 'dashboard.php', { params })
            .then(response => {
                // JSON responses are automatically parsed.
                return response.data;
            })
            .catch(e => {
                // eslint-disable-next-line
                console.log(e);
            });
        return ajax;
    }

}