<template>
  <div class="menu">
      <div class="menuTitulo">
        Ocupação
      </div>
      <div class="logout" @click="logout()">
        <svg class="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path></svg>
      </div>

  </div>
</template>


<script>

    import utilizadoresService from '@/services/utilizadores'

export default {
  name: 'Menu',
  components: {

  },
  data: function () {
    return {

    }
  },
  methods: {

      logout: function(){

          let that = this;

          // document.getElementById('app').exitFullscreen();
          document.exitFullscreen();

          utilizadoresService.logout()
              .then(function(){
                  that.$router.push('/');
              })

      }



  },
  mounted: function(){


  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .menu{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: $corEscura;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 15px;
    z-index: 10;
  }

  .menuTitulo{
    font-weight: bold;
  }

  .logout{
    cursor: pointer;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      max-height: 32px;
    }
  }


</style>